import { useEffect } from "react";
import { navigate } from "gatsby";

export default function Redirect({ pageContext }) {
	const { originalPath } = pageContext;
	const { fallback: fallbackLng, supported: supportedLngs } = SITE_LANGUAGES;
	let preferredLang = getPreferredLang();
	if (!supportedLngs[preferredLang]) preferredLang = fallbackLng;
	useEffect(() => {
		navigate(`/${preferredLang}${originalPath === "/" ? "" : originalPath}`);
	}, []);
	return null;
}

export function getPreferredLang() {
	let found = [];

	if (typeof navigator !== "undefined") {
		if (navigator.languages) {
			// chrome only; not an array, so can't use .push.apply instead of iterating
			for (let i = 0; i < navigator.languages.length; i++) {
				found.push(navigator.languages[i]);
			}
		}
		if (navigator.userLanguage) {
			found.push(navigator.userLanguage);
		}
		if (navigator.language) {
			found.push(navigator.language);
		}
	}

	return found.length > 0 ? found[0].split("-")[0] : undefined;
}
